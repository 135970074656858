<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 银行海报
 * @Date: 2020-11-19 17:41:02
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-11-22 22:38:05
 * @FilePath: /yue_quanzhan_h5_new/src/views/localLife/creditCard/infoposter.vue
-->
<template>
  <div class="content">
    <vue-canvas-poster :width-pixels="1000" :painting="painting" @success="success" @fail="fail" />
    <div v-if="imgshow" class="cloth">
      <img :src="imagePath" alt="">
    </div>
    <div v-if="showShare" class="share" style="padding: 5px 30px;">
      <p style="text-align: center;">长按图片保存到手机相册</p>
      <p><span>*</span>点击右上角分享给用户，好友通过您的海报扫码申请办卡，Ta将成为您的客户，TA后续办卡您获得推广奖励！</p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { generatePosterForBank } from '@/services/comeOn.js'
import { VueCanvasPoster } from 'vue-canvas-poster'
import { Icon, Toast } from 'vant'
import { isWeChat } from '@/utils/common/validate'
import { wxConfigInit } from '@/utils/wx-api'

Vue.use(Icon)
Vue.use(Toast)
export default {
  components: {
    VueCanvasPoster
  },
  data() {
    return {
      mid: '',
      bankId: '',
      type: '',
      desc: '',
      img: '',
      noQrImg: '',
      shareUrl: '',
      title: '',
      smallImg: '',
      showShare: true,
      showShares: true,
      imgshow: true,
      wxfx: false,
      options: [
        { name: '复制链接', icon: require('@/assets/images/z-1.png'), key: 1 },
        { name: '生成海报', icon: require('@/assets/images/z-2.png'), key: 2 }
        // { name: '更多海报', icon:require('@/assets/images/z-3.png'), key:3 },
      ],
      sharelsit: [
        { name: '微信', icon: require('@/assets/images/fx_1.png'), key: 0 },
        { name: '朋友圈', icon: require('@/assets/images/fx_2.png'), key: 1 },
        { name: '保存相册', icon: require('@/assets/images/fx_3.png'), key: 2 }
      ],
      imagePath: '',
      painting: {
        width: `${document.documentElement.clientWidth}px`,
        height: `${document.documentElement.clientWidth * 1.777}px`,
        background: '#f8f8f8',
        views: [
          // {
          //   type:'image',
          //   url:this.noQrImg + '',
          //   css:{
          //     width:'550px',
          //     height:'876px'
          //   }
          // },
          // {
          //   type: 'text',
          //   text: '扫描二维码注册并申请',
          //   css: {
          //       padding: '15px',
          //       fontSize: '20px',
          //       textAlign: 'centen',
          //       lineHeight: '15px',
          //       bottom:`${document.documentElement.clientWidth/7.5}px`,
          //       left:`${document.documentElement.clientWidth/4}px`
          //   }
          // },
          // {
          //   type:'rect',
          //   css:{
          //     bottom: `${document.documentElement.clientWidth/5.5}px`,
          //     left: `${document.documentElement.clientWidth/2.6}px`,
          //     color: '#fff',
          //     width: `${document.documentElement.clientWidth/4.0}px`,
          //     height: `${document.documentElement.clientWidth/4.0}px`
          //   }
          // },
          // {
          //   type:'qrcode',
          //   content:this.shareUrl + '',
          //   css:{
          //     bottom: `${document.documentElement.clientWidth/5}px`,
          //     left: `${document.documentElement.clientWidth/2.5}px`,
          //     color: '#000',
          //     width: `${document.documentElement.clientWidth/4.7}px`,
          //     height: `${document.documentElement.clientWidth/4.7}px`
          //   }
          // }
        ]
      }
    }
  },
  created() {
    this.info()
    this.getposter()
    this.shareConfig()
  },
  methods: {
    // 点击立即分享
    shareAgain() {
      this.wxfx = true
      this.showShare = true
    },
    info() {
      // 更改title
      document.title = this.$route.query.title + '推广海报'
      this.mid = this.$route.query.mid
      this.bankId = this.$route.query.bankId
      this.typeId = this.$route.query.typeId
      this.merCardId = this.$route.query.merCardId
    },
    // 分享配置
    shareConfig() {
      let bankId, type
      if (this.typeId === '1') {
        bankId = this.bankId
        type = this.typeId
      } else if (this.typeId === '2') {
        bankId = this.merCardId
        type = this.typeId
      }
      const shareInfo = {
        title: '银行官方办卡渠道，权益多样，返现超高', // 分享标题
        desc: '申请拿返现，邀新单单赚，快来点击查看吧～', // 分享描述
        // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        link: 'https://web.yuetao.vip/singleCardShare?codeNumber=' +
          window.localStorage.getItem('recode') +
          '&bankId=' + bankId +
          '&type=' + type,
        imgUrl: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-17/20/yuelvhui8drkr79XTe1605616133.png' // 分享图标
      }
      wxConfigInit(shareInfo)
      // this.$store.dispatch('wxConfigInit', shareInfo)
    },
    onSelect() {
      this.imgshow = true
      this.showShare = false
      this.showShares = false
    },
    // 分享
    onshlist(t) {
      if (isWeChat()) {
        const ajaxUrl = 'https://gateway.yuetao.vip/center/v1/app/WechatShareH5?share_url=' + encodeURIComponent(window.location.href)
        // eslint-disable-next-line no-undef
        $.ajax({
          url: ajaxUrl,
          type: 'GET',
          dataType: 'jsonp',
          success: data => {
            // eslint-disable-next-line no-undef
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: data.app_id, // 必填，企业号的唯一标识，此处填写企业号corpid
              timestamp: data.timestamp, // 必填，生成签名的时间戳
              nonceStr: data.noncestr, // 必填，生成签名的随机串
              signature: data.signature, // 必填，签名，见附录1
              jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'downloadFile'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
            })
            if (t.key === 0) {
              // Toast('微信')
              // eslint-disable-next-line no-undef
              wx.ready(function() {
                // eslint-disable-next-line no-undef
                wx.updateTimelineShareData({
                  title: this.title,
                  desc: this.desc,
                  link: this.shareUrl,
                  imgUrl: this.img,
                  success: res => {
                  }
                })
              })
            } else if (t.key === 1) {
              // Toast('圈')
              // eslint-disable-next-line no-undef
              wx.ready(function() {
                // eslint-disable-next-line no-undef
                wx.updateAppMessageShareData({
                  title: this.title,
                  desc: this.desc,
                  link: this.shareUrl,
                  imgUrl: this.img,
                  success: res => {
                  }
                })
              })
            } else if (t.key === 2) {
              // eslint-disable-next-line no-undef
              wx.ready(function() {
                // eslint-disable-next-line no-undef
                wx.downloadFile({
                  url: this.imagePath,
                  success: res => {
                    Toast.success('保存成功')
                  }
                })
              })
            }
          }
        })
      } else {
        return false
      }
    },
    canc() {
      this.$router.push({
        path: '/Credit'
      })
    },
    success(src) {
      this.imagePath = src
    },
    fail(err) {
      console.log('fail', err)
    },
    getposter() {
      const poster = {
        mid: this.mid,
        bankId: this.bankId,
        type: this.typeId,
        merCardId: this.merCardId,
        text: 1// 测试传1不走缓存
      }
      generatePosterForBank(poster).then(res => {
        if (Number(res.code) === 200) {
          this.success(res.data.img)
          this.desc = res.data.desc
          this.img = res.data.img
          this.noQrImg = res.data.noQrImg
          this.shareUrl = res.data.shareUrl
          this.title = res.data.title
          this.smallImg = res.data.smallImg
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
input {
  display: none;
}

.content {
  background-color: #f8f8f8;
  min-height: 100vh;

  .cloth {
    min-height: 80vh;
    padding: 10px 39px 0;

    div {
      height: 35em;
      background-size: 100% 100%;
      position: relative;

      .wmbox {
        width: 160px;
        height: 84px;
        position: absolute;
        left: 50%;
        margin-left: -80px;
        bottom: 40px;

        .wm {
          width: 84px;
          height: 84px;
          margin: auto;
          background-color: #fff;
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        p {
          color: #000000;
          font-size: 12px;
          margin-top: 20px;
        }
      }

    }

    // img{
    //   height: 80vh;
    // }
  }

  .cloth2 {
    min-height: 80vh;

    div {
      min-height: 80vh;
      background-size: 100% 100%;
    }
  }

  .share {
    // height: 20vh;
    background-color: #F8F8F8;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding: 21px 33px;

    .ShareImmediately {
      width: 100%;
      height: 45px;
      line-height: 45px;
      text-align: center;
      color: #fff;
      font-size: 15px;
      font-weight: 500;
      background: -webkit-linear-gradient(top, #FED4B4, #DDA67C);
      border-radius: 7px;
      margin: 0 auto;
    }

    p {
      font-size: 13px;
      color: #000;
      font-weight: 400;
      line-height: 17px;
      text-align: left;
    }

    p:nth-child(2) {
      margin-top: 5px;

      span {
        color: red;
      }
    }

    .isclos {
      padding: 15px;
      margin-top: 10px;
      color: #333333;
      background-color: #fff;
    }

    .btn {
      color: #FFFFFF;
      background-image: linear-gradient(#1F83EE, #1F39ED);
      width: 100%;
      padding: 15px;
      border-radius: 7px;
    }
  }
}
</style>
